<template>
  <div class="Tmall-goods">
    <NavBar :class="{ 'nav-bar': navBar }" :iconColor="'#fff'">
      <img
        slot="title"
        src="@/assets/goods/Tmall-title.png"
        class="title-img"
      />
    </NavBar>
    <img src="@/assets/goods/Tmall-back.png" class="back-img" />
    <div class="search-layout" :class="{ 'nav-bar': navBar }">
      <Search
        backColor="linear-gradient(90deg, #AC78FE 0%, #8D4EFE 100%)"
        @searchGoods="searchGoods"
      ></Search>
    </div>
    <div class="goods-layout">
      <GoodsList ref="goodsList" tagText="天猫商品" tagType="Tmall"></GoodsList>
    </div>
    <SearchPage
      :showSearch="showSearch"
      @setSearchStr="setSearchStr"
      @retreat="retreat"
    ></SearchPage>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar.vue";
import Search from "../components/search/search";
import GoodsList from "../components/GoodsList/GoodsList";
import SearchPage from "@/pages/goods/goods-list/moudles/search-page";
export default {
  components: {
    NavBar,
    Search,
    GoodsList,
    SearchPage,
  },
  mounted() {
    addEventListener("scroll", this.handleScroll, true);
  },
  data() {
    return {
      navBar: false,
      showSearch: false,
    };
  },
  methods: {
    retreat() {
      this.showSearch = false;
    },
    setSearchStr(searchStr) {
      this.retreat();
      this.$router.push({
        name: "search-page",
        query: { searchStr: searchStr },
      });
    },
    handleScroll() {
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > 40) {
        this.navBar = true;
      } else {
        this.navBar = false;
      }
    },
    searchGoods(goodsName) {
      this.showSearch = true;
      // if (goodsName !== undefined && goodsName !== "") {
      //   this.$refs.goodsList.selectGoodsList(goodsName);
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.Tmall-goods {
  position: relative;
  width: 100vw;
  min-height: 100vh;

  .back-img {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 179px;
    z-index: -1;
  }
}

.nav-bar {
  background-color: #9356fe;
}

.title-img {
  width: 104px;
  height: 32px;
}

.search-layout {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  box-sizing: border-box;
}

.goods-layout {
  padding-top: 118px;
}
</style>
